import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fallido',
  templateUrl: './fallido.component.html',
  styleUrls: ['./fallido.component.css']
})
export class FallidoComponent {
  cantidad: number;
  constructor(private authService: AuthService, private route: ActivatedRoute) {
    this.cantidad = Math.round(parseFloat(this.route.snapshot.params['cantidad']) * 100) / 100
  }

  botonPago() {
    this.authService.reseteaStorage(true);
  }

}
