import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentMainComponent } from './content-main/content-main.component';
import { LoandigComponent } from './loandig/loandig.component';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';



@NgModule({
  declarations: [
    ContentMainComponent,
    LoandigComponent
  ],
  imports: [
    CommonModule,
    PrimeNgModule
  ],
  exports: [
    ContentMainComponent,
    LoandigComponent
  ]
})
export class ComponentsModule { }
