import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SolicitudComponent } from './pages/solicitud/solicitud.component';
import { ExitosoComponent } from './pages/exitoso/exitoso.component';
import { FallidoComponent } from './pages/fallido/fallido.component';
import { SecurityGuard } from './security.guard';

const routes: Routes = [
  {
    path: 'solicitud',
    component: SolicitudComponent,
  },
  {
    path: 'exitoso/:code/:vigencia',
    component: ExitosoComponent,
    // canActivate: [SecurityGuard],
  },
  {
    path: 'fallido/:cantidad',
    component: FallidoComponent,
    // canActivate: [SecurityGuard],
  },
  {
    path: '**',
    redirectTo: 'solicitud',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
