import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from "src/environments/environment.development";
import { Observable } from 'rxjs';
import { APIDesbloqueo, requestInterface } from 'src/app/interfaces/APIDesbloqueo.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiDesbloqueoService {

  urlApiDesbloqueo = environment.urlApiDesbloqueo;

  constructor( private http: HttpClient) { }

  // *Servicio para obtener los códigos de desbloqueo
  getCodigoDesbloqueoPorPost(body:requestInterface): Observable<APIDesbloqueo> {
    const apikey = 'k7MBkv2rbj2g3hqgctTlX9v2PyDlpNoc3K1crWhe';
    const header = new HttpHeaders({'x-api-key': apikey,'Content-Type':'application/json'});
    return this.http.post<APIDesbloqueo>(`${this.urlApiDesbloqueo}`,body,{headers: header});
  }
}
