import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiDesbloqueoService } from '../../services/backend/api-desbloqueo.service';
import { AuthService } from '../../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';

import { environment } from "src/environments/environment.development";
import { requestInterface } from 'src/app/interfaces/APIDesbloqueo.interface';


@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.css']
})
export class SolicitudComponent implements OnInit {
  spinner = false;
  @ViewChild('captchaElem') captchaElem!: ReCaptcha2Component;

  displayDialog: boolean = false;
  mensajeDialog: string = '';
version = environment.version;
  dataSolicitudForm: FormGroup;
  datosDesbloqueo: any[] = [];
  identifier: string = '';
  camposTouched={
    numeroCelular:false,
    numeroCuenta:false,
    email:false
  }

  siteKey: string = '';

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private apiDesbloq: ApiDesbloqueoService,
    private authService: AuthService,
    private ngxService: NgxUiLoaderService) {
    this.dataSolicitudForm = this.formBuilder.group({
      numeroCuenta: [null],
      numeroCelular: [null],
      email: [null],
      recaptcha: ['', Validators.required]
    });

    this.siteKey = environment.siteKey;
  }

  ngOnInit(): void {
    this.dataSolicitudForm.get('email')?.valueChanges.subscribe((value:any)=>{
      this.dataSolicitudForm.removeValidators;
      this.dataSolicitudForm.get('recaptcha')?.setValidators(Validators.required);
      if(value==='' ){
        this.dataSolicitudForm.get('email')?.setValue(null);
      }else{
        this.dataSolicitudForm.get('email')?.setValidators([Validators.email]);
      }

    })
    this.dataSolicitudForm.get('numeroCelular')?.valueChanges.subscribe((value:any)=>{

      this.dataSolicitudForm.removeValidators;
      this.dataSolicitudForm.get('recaptcha')?.setValidators(Validators.required);
      if(value==='' ){
        this.dataSolicitudForm.get('numeroCelular')?.setValue(null);
      }else{
        this.dataSolicitudForm.get('numeroCelular')?.setValidators( [
          Validators.minLength(10),
          Validators.maxLength(10),
        ]);
      }

    });
    this.dataSolicitudForm.get('numeroCuenta')?.valueChanges.subscribe((value:any)=>{

      this.dataSolicitudForm.get('recaptcha')?.setValidators(Validators.required);
      this.dataSolicitudForm.removeValidators;
      if(value==='' ){
        this.dataSolicitudForm.get('numeroCuenta')?.setValue(null);
      }else{
        this.dataSolicitudForm.get('numeroCuenta')?.setValidators( [
          Validators.minLength(10),
          Validators.maxLength(10),
        ]);
      }

    });

  }

  showDialog(text1: string) {
    this.displayDialog = true;
    this.mensajeDialog = text1;
  }
  respuestaSolicitud() {
    const {email,numeroCuenta,numeroCelular} =this.dataSolicitudForm.value;
    console.log(numeroCuenta);

    if ((email === "" || email === null) && (numeroCuenta === "" || numeroCuenta === null) && (numeroCelular === "" || numeroCelular === null)) {
      this.showDialog('Para generar el código de desbloqueo es necesario al menos uno de los datos solicitados');
      return;
    }

    if(numeroCuenta !== null){
      if(numeroCuenta.length<10){
        this.showDialog('El número de cuenta ingresado no tiene el formato correcto.');
        return ;
      }

    }
    if(numeroCelular !== null){
      if(numeroCelular.length<10){
        this.showDialog('El número de celular ingresado no tiene el formato correcto.');
        return ;
      }

    }
    if (this.dataSolicitudForm.get('recaptcha')?.value === "") {
      this.showDialog('¡Te faltó el captcha!');
      return;
    }
      this.spinner = true;
      const data: requestInterface = {
        payload: {
          cuenta: this.dataSolicitudForm.get('numeroCuenta')?.value,
          email: this.dataSolicitudForm.get('email')?.value,
          msisdn: this.dataSolicitudForm.get('numeroCelular')?.value
        }

      }
      this.apiDesbloq.getCodigoDesbloqueoPorPost(data)
        .subscribe((response:any) => {
          this.datosDesbloqueo.push(response);
          const { identifier, responseObject, process } = response
          if (process) {

            const { cantidad, status } = responseObject;
            if (identifier === 200) {
              sessionStorage.setItem('numeroCuenta', this.dataSolicitudForm.get('numeroCuenta')?.value);
              this.ngxService.stop();
              this.router.navigate(['/exitoso/' + responseObject.unlock_code + '/' + responseObject.validity_of_unlock_code]);
            } else {
              if (status === 'due') {
                this.router.navigate(['/fallido/' + cantidad]);
                this.ngxService.stop();
              }
              if (status === 'paid') {
                this.showDialog('Tu préstamo está liquidado, comunicate al *264');
              }
              if (status === 'unlock') {
                this.showDialog('El número de cuenta ingresado se encuentra sin bloqueo.');
              }
            }
          } else {
            if (this.dataSolicitudForm.get('numeroCuenta')?.value !== null && this.dataSolicitudForm.get('numeroCuenta')?.value !== '') {
              this.showDialog('El número de cuenta ingresado no lo tenemos registrado, ingresa el dato correcto.');
            }else if (this.dataSolicitudForm.get('email')?.value !== null && this.dataSolicitudForm.get('email')?.value !== '') {
              this.showDialog('La dirección de correo electrónico ingresada no la tenemos registrado, ingresa el dato correcto');
            }else   if (this.dataSolicitudForm.get('numeroCelular')?.value !== null && this.dataSolicitudForm.get('numeroCelular')?.value !== '') {
              this.showDialog('El número de celular ingresado no lo tenemos registrado, ingresa el dato correcto.');
            }
          }
          this.spinner = false;
        })



  }
}
