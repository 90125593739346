import { NgModule } from '@angular/core';

import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {CaptchaModule} from 'primeng/captcha';
import {ToastModule} from "primeng/toast";
import {TooltipModule} from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import {InputMaskModule} from 'primeng/inputmask';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
  declarations: [],
  imports: [
  ],
  exports: [
    ButtonModule,
    CaptchaModule,
    ChipModule,
    DialogModule,
    InputMaskModule,
    InputTextModule,
    ToastModule,
    TooltipModule,
    ProgressSpinnerModule
  ]
})
export class PrimeNgModule { }
