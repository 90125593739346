<div class="logoRegisterContainer h-100vh">
  <div class="flex flex-column justify-content-end">
    <div class="flex flex-grow-1 align-items-center justify-content-center">
      <img src="/assets/images/amigo_logo.svg" alt="Amigo telcel logo" class="filter-blue">
    </div>
    <app-content-main>
      <div class="flex flex-column justify-content-end align-items-center  letra-telcel">
        <h4 class="app-text-blue mt-4 p-chip-text"><b>SOLICITUD DE DESBLOQUEO</b></h4>
        <p class="p-component app-text-blue text-center mt-0">Puedes recuperar tu código con alguno de los siguientes
          datos.</p>
        <div class="flex flex-column align-self-start pl-3 pr-3 w-full">
          <form [formGroup]="dataSolicitudForm" (submit)="respuestaSolicitud()">
            <div class="field p-component app-text-blue mb-1">
              <div class="p-inputgroup mt-2">
                <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                <input  type="tel" pInputText id="numeroCuenta" class="w-full" formControlName="numeroCuenta" required
                  maxlength="10" pTooltip="Ingresa tu número de cuenta"
                  onkeypress=" return (event.charCode >= 48 && event.charCode <= 57)" placeholder="Número de Cuenta">
              </div>
            </div>
            <div class="field p-component app-text-blue mb-1">
              <div class="p-inputgroup mt-3">
                <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
                <input  type="tel" pInputText id="numeroCelular" class="w-full" formControlName="numeroCelular" required
                  maxlength="10" pTooltip="Ingresa tu número celular a 10 dígitos"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  placeholder="Número Telefónico a 10 dígitos">
              </div>

            </div>
            <div class="field p-component app-text-blue mb-1">
              <div class="p-inputgroup mt-3">
                <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
                <input type="email" pInputText id="email" class="w-full" formControlName="email"
                  pTooltip="Ej. usuario@dominio.com" placeholder="Escribe tu correo electrónico">
              </div>

            </div>

            <div class=" flex flex-row field p-component mb-4 mt-4 justify-content-center">
              <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" pTooltip="Confirma que no eres un robot"
                formControlName="recaptcha">
              </ngx-recaptcha2>

            </div>
            <div class="flex flex-column mt-3 mb-3">
              <button type="submit" pButton class="primaryButton p-button-sm" label="SOLICITAR CÓDIGO">
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="pl-2 pr-2">
        <p class="text-xs text-center">{{version}}</p>
    </div>

    </app-content-main>
    <p-dialog header="AVISO IMPORTANTE" [(visible)]="displayDialog" [modal]="true" [style]="{width: '70vw'}"
      [draggable]="false" [resizable]="false" [closable]="false">
      <p class="m-0">{{ mensajeDialog }}</p>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayDialog=false" label="Cerrar" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>

<div *ngIf="spinner">
  <app-loandig></app-loandig>
</div>
