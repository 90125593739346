import { Component, Input } from '@angular/core';

import { ApiDesbloqueoService } from '../../services/backend/api-desbloqueo.service';
import { AuthService } from '../../services/auth.service';
import { APIDesbloqueo } from 'src/app/interfaces/APIDesbloqueo.interface';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-exitoso',
  templateUrl: './exitoso.component.html',
  styleUrls: ['./exitoso.component.css']
})
export class ExitosoComponent {
  codigoDesbloq: string = '';
  vigencia: number = 0;

  // *Variables para mostrar información en HTML
  datosDesbloqueo: any = [];


  // *Importación de servicios Router para navegación y apiDesbloq para mostrar código
  constructor(private apiDesbloq: ApiDesbloqueoService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.codigoDesbloq = this.route.snapshot.params['code'];
    this.vigencia = parseInt(this.route.snapshot.params['vigencia']) / 60000;
    console.log(new Date(this.vigencia))
    // *Función para obtener los datos del código de desbloqueo
  }

  // *Navegando a la ruta de salida
  finaliza() {
    this.authService.reseteaStorage();
  }
}
