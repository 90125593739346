import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { NgxUiLoaderModule } from "ngx-ui-loader";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';

import { JwtModule } from "@auth0/angular-jwt";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
          tokenGetter: function tokenGetter() {
              return sessionStorage.getItem('tokenTelcel');
          },
          allowedDomains: ['localhost:4200', 'localhost:8080'],
          disallowedRoutes: ['']
      }
    }),
    NgxUiLoaderModule,
    PagesModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    sessionStorage.setItem('tokenTelcel', 'tokenTelcel is null');
  }
 }
