import { Component } from '@angular/core';

@Component({
  selector: 'app-loandig',
  templateUrl: './loandig.component.html',
  styleUrls: ['./loandig.component.css']
})
export class LoandigComponent {

}
