<div class="bgContainerBlue h-100vh">
  <div class="flex flex-column justify-content-end ">
    <div class="flex flex-grow-1 align-items-center justify-content-center">
      <img src="/assets/images/amigo_logo.svg" alt="Amigo telcel logo">
    </div>
    <app-content-main>
      <div class="flex flex-column justify-content-end align-items-center ">
        <div class="flex flex-grow-1 align-items-center justify-content-center mt-1">
          <img src="/assets/images/sorry.jpg" alt="Amigo telcel logo" width="84%" class="amigo_telcel_logo">
        </div>
        <h4 class="app-text-blue mt-3 letra-blanca text-center"><b><strong>Tu cuenta tiene un adeudo de  {{ cantidad | currency  }} MXN</strong></b></h4>
        <h4 class="app-text-blue mt-3 letra-blanca text-center"><b><strong>Y no pudimos generarte un código </strong></b></h4>

      </div>
      <div class="mx-3 my-3 grid">
        <button pButton type="button" class="primaryButton" label="Te invitamos a pagar aquí
        " (click)="botonPago()">
        </button>
      </div>
    </app-content-main>
  </div>
</div>
