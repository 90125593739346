<div class="bgContainerBlue h-100vh">
  <div class="flex flex-column justify-content-end ">
    <div class="flex flex-grow-1 align-items-center justify-content-center">
      <img src="/assets/images/amigo_logo.svg" alt="Amigo telcel logo">
    </div>
    <app-content-main>
      <div class="flex flex-column justify-content-end align-items-center ">
        <div class="flex flex-grow-1 align-items-center justify-content-center mt-1">
          <img src="/assets/images/ok.jpg" alt="Amigo telcel logo" width="84%" class="amigo_telcel_logo">
        </div>
        <h3 class="app-text-blue mt-3 letra-blanca text-center"><b><strong>Ingresa el siguiente código en tu teléfono:</strong></b> </h3>
        <h3 class="app-text-blue mt-3 letra-blanca text-center"><b><strong>{{ codigoDesbloq }}</strong></b> </h3>
        <h3 class="app-text-blue mt-3 letra-blanca text-center"><b><strong>Tienes {{ vigencia }} minutos para usarlo</strong></b> </h3>
      </div>
      <div class="mx-3 my-3 grid">
        <button pButton class="primaryButton p-button-sm" label="FINALIZAR" (click)="finaliza()">
        </button>
      </div>
    </app-content-main>
  </div>
</div>
