import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Valida } from '../interfaces/valida.interface';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from "src/environments/environment.development";
import { Router } from '@angular/router';
import { requestInterface } from '../interfaces/APIDesbloqueo.interface';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlUsuarios = environment.urlUsuarios;

  constructor( private http: HttpClient,
               private jwtHelper: JwtHelperService,
               private router: Router) {

  }

  public isAuthenticated(): boolean {
    const tokenTelcel = sessionStorage.getItem('tokenTelcel');
    if (tokenTelcel == '' || tokenTelcel == null || tokenTelcel == 'tokenTelcel is null') {
      return false;
    }
    /*
      let isTokenExpired = this.jwtHelper.isTokenExpired(tokenTelcel);
      if(isTokenExpired == true) {
          return false;
      }
    */
    return true;
}

  getUsuarios(body:requestInterface) {
    const apikey = 'ZK4sgrqRa434JvWef8vbjaVxc1y4fEUD5fXvfFgW';
    const header = new HttpHeaders({'x-api-key': apikey});
    return this.http.post<Valida>(`${this.urlUsuarios}`,body,{headers: header});
  }

  reseteaStorage( redirecciona: boolean = false ) {

    if (redirecciona) {
      window.open('https://padnet.telcel.com/apt/home/?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJQSUQiOiI4NmE0YzY4Ni0yNTRkLTQ2N2YtODBhNS01ZGI4MzE5MzQwMDciLCJhY2NvdW50SUQiOm51bGwsImFwcCI6InBhZG5ldCIsImNjIjoiX05VTEwiLCJkZXNrdG9wVmlld2VyIjp0cnVlLCJleHAiOjE2NzgzMjI3ODksImhvc3RPcmlnaW4iOiJwYWRuZXQudGVsY2VsLmNvbS9hcHQiLCJob3N0VGFyZ2V0IjoiaHR0cHM6Ly9wYWRuZXQudGVsY2VsLmNvbS9hcHQvaG9tZSIsImxvYiI6bnVsbCwibmJmIjoxNjc4MzE5MTg5LCJ3aWZpIjoiVHJ1ZSJ9.6Le0dWzDeZdY3DqoJXh3g7kImOl_H9S0NMCbfhhsvNw', '_blank');
    }
    sessionStorage.setItem('tokenTelcel', 'tokenTelcel is null');
    sessionStorage.removeItem('numeroCuenta');
    this.router.navigate(['/']);
  }
}
