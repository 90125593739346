import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';

import { SolicitudComponent } from './solicitud/solicitud.component';
import { ExitosoComponent } from './exitoso/exitoso.component';
import { FallidoComponent } from './fallido/fallido.component';
import { ComponentsModule } from '../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { NgxCaptchaModule } from 'ngx-captcha';



@NgModule({
  declarations: [
    SolicitudComponent,
    ExitosoComponent,
    FallidoComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NgxCaptchaModule,
    PrimeNgModule,
    ReactiveFormsModule,
  ],
  exports: [
    SolicitudComponent,
    ExitosoComponent,
    FallidoComponent
  ],
  providers: [MessageService]
})
export class PagesModule { }
